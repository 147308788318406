/* 
 * @Author: 曹俊杰 
 * @Date: 2021-01-13 18:41:54
 * @Module: 消息中心
 */
 <template>
  <div>
    <div class="notice-box">
      <div v-for="item in activeList"
           class="df hover"
           :key="item.key"
           @click="$router.push({name:item.key})"
           :class="{ac:item.key==active}">
        {{item.title}}
      </div>
    </div>
    <router-view />
  </div>

</template>
 <script>
export default {
  components: {},
  data () {
    return {
      activeList: [
        {
          title: "收到的赞",
          key: "like"
        },
        {
          title: "系统通知",
          key: "system"
        }, {
          title: "互动消息",
          key: "comment"
        }
      ],
      active: "like"
    };
  },
  mounted () { },
  methods: {},
  watch: {
    $route: {
      handler: function (val) {
        this.active = val.name
      },
      // 深度观察监听
      deep: true,
      immediate: true
    }
  },
};
 </script>
 <style lang='scss' scoped>
.notice-box {
  display: flex;
  background: #fff;

  .df,
  .ac {
    width: 120px;
    line-height: 58px;
    margin-left: 20px;
    text-align: center;
    color: #666666;
  }
  .ac {
    border-bottom: 2px solid #df2a29;
    color: #262626;
  }
}
</style>